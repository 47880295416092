<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/system/breadcrumbs.module";

export default {
  name: "SetupProject",

  mounted() {
    let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `${this.$t("NAVIGATION.ROOT_MENU.CLIENTS")}`, route: protocol + 'admin.' + process.env.VUE_APP_API_ROOT + "/#/clients/view" },
      { title: this.clientInfo.name, route: protocol + this.clientInfo.key + ".admin." + process.env.VUE_APP_API_ROOT + "/#/client/projects" },
      {
        title: this.projectInfo.name,
        route: "/project/view"
      },
      { title: `${this.$t("NAVIGATION.PROJECT_MENU.SETUP")}` }
    ]);
  },

  computed: {
    ...mapGetters(["clientInfo", "projectInfo"])
  }
};
</script>
